import { useState } from 'react';
import './App.css';

import Snackbar from '@mui/material/Snackbar';

const TOTAL_SUPPLY = `100,000,000`
const CONTRACT_ADDRESS = '8bf25wWvJgWVsbJ6fEoreUyBE9jJnyJG7weLG7PatrJ4'

function App() {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div className="App">
      <div className="Header">
        <span><img alt="Logo" className='logo' src="/logo.png" /></span>
        <span>
          <a href="https://twitter.com/warholonsol" target="_blank" rel="noreferrer">
            <img alt="Warhol Twitter" className='button margin' src="/button-twitter.png" />
          </a>
          <a href="https://t.me/thefactorysol" target="_blank" rel="noreferrer">
            <img alt="Warhol Telegram" className='button' src="/button-telegram.png" />
          </a>
        </span>
      </div>
      <div className="Body">
        <div className='col1'>
          <img alt="Warhol 1" className='main' src="/main1.jpg" />
          <div className='inner-container'>
            <div className='main-text-container'>
            <div className='main-text'>{"\"Bull markets come and go."}</div>
            <div className='main-text'>{"When you're comfortable in your own skin, that's all that matters\""}</div>
            </div>
            <div className='main-text-container no-padding'>$WARHOL on <a target="_blank" rel="noreferrer" href="https://birdeye.so/token/8bf25wWvJgWVsbJ6fEoreUyBE9jJnyJG7weLG7PatrJ4?chain=solana">Birdeye</a>, <a target="_blank" rel="noreferrer" href="https://dexscreener.com/solana/bmqc45my7tysmvr5subaikug6fzqe7tjvdpxrsscrkww">DEX Screener</a>, <a target="_blank" rel="noreferrer" href="https://jup.ag/swap/SOL-WARHOL_8bf25wWvJgWVsbJ6fEoreUyBE9jJnyJG7weLG7PatrJ4">Jupiter</a>, <a target="_blank" rel="noreferrer" href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=8bf25wWvJgWVsbJ6fEoreUyBE9jJnyJG7weLG7PatrJ4">Raydium</a>, <a target="_blank" rel="noreferrer" href="https://rugcheck.xyz/tokens/8bf25wWvJgWVsbJ6fEoreUyBE9jJnyJG7weLG7PatrJ4">rugcheck.xyz</a></div>
            <img alt='Copy Contract Address' onClick={() => { setOpen(true); navigator.clipboard.writeText(CONTRACT_ADDRESS) } } className='button' src="/contract-button.png" />
            <div className='main-text-container no-padding'><a href="https://t.me/thefactorysol" target="_blank" rel="noreferrer">Join The Factory on Telegram.</a></div>
          </div>
        </div>
        <div className="col2">
          <img alt="Warhol 2" className='main' src="/main2.jpg" />
          <div className="memepack"><span>🎨</span><a href="https://drive.google.com/drive/folders/1L19fkfPZS5AdiaErc_kSdKLa0vs4pVX6" target="_blank" rel="noreferrer">Get the meme pack.</a></div>
        </div>
        <div className='col3'>
          <div className='inner-container'>
            <div className='inner-header'>Rules of the Factory:</div>
            <div>No buyer or seller's tax.</div>
            <div>Minting revoked.</div>
            <div>Liquidity burned.</div>
            <div>Total supply: {TOTAL_SUPPLY}</div>
            <div className='light-text'>TEAM: 7.5%  |  PRODUCTION 5%  |  PARTIES 2%</div>
          </div>
          <img alt='Warhol 3' className='main' src="/main3.jpg" />
          <div className='inner-container'>
            <div className='ticker'>{"\"Ticker symbols weren't just what you bought, they were the whole reason for going.\""}</div>
            <img alt='Copy Contract Address' onClick={() => { setOpen(true); navigator.clipboard.writeText(CONTRACT_ADDRESS) } } className='button' src="/contract-button.png" />
            <div className='disclaimer'>
              <div>DISCLAIMER</div>
              <div>$WARHOL IS A MEMETIC ART TOKEN ON THE SOLANA BLOCKCHAIN WHOSE VALUE SHOULD BE TAKEN ONLY AS SERIOUSLY AS A CAN OF SOUP.  THE PRICE MAY GO UP OR IT MAY GO DOWN. WE ARE NOT RESPONSIBLE FOR THE PRICE OF THE TOKEN.</div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        message="Copied!"
        key={'bottom center'}
      />
    </div>
  );
}

export default App;
